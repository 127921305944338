import React, { useEffect } from 'react'
import firebase from 'gatsby-plugin-firebase'
import { Formik, Field, Form } from 'formik'
import styled from 'styled-components'

import { ColMixin, ContainerMixin, RowMixin } from '../elements'

import checkedIcon from '../../images/checked-icon.svg'

const Col = styled.div`
  ${ColMixin};

  button {
    background-color: ${(props) => props.theme.colorGreen};
    border: 1px solid ${(props) => props.theme.colorGreen};
    color: #fff;
    cursor: pointer;
    border-radius: 30px;
    display: inline-block;
    font-size: 25px;
    font-weight: bold;
    line-height: 25px;
    margin-top: 20px;
    padding: 15px 35px;
    position: relative;
    transition: ease 0.2s all;

    &:hover {
      background-color: transparent;
      color: ${(props) => props.theme.colorGreen};
    }
  }

  input {
    border: 1px solid ${(props) => props.theme.colorGreen};
    display: inline-block;
    font-size: 23px;
    height: 58px;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    &:focus {
      border-radius: 0;
    }

    &.error {
      border-color: #ff122c;
    }
  }

  p {
    margin: -20px 15px 30px 15px;
    text-align: left;
  }
`

const FormColField = styled(Col)`
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const Error = styled.div`
  bottom: 10px;
  color: #ff122c;
  left: 15px;
  position: absolute;
`

const FormWrapper = styled.div`
  margin: 0 auto;
  max-width: 75%;
  position: relative;
  top: 50%;
  /* transform: translateY(-50%); */
  padding-bottom: 50px;
  @media (max-width: 1599px) {
    position: static;
    padding-bottom: 30px;
    transform: none;
  }
  @media (max-width: 991px) {
    max-width: 100%;
  }
`

const Row = styled.div`
  ${RowMixin};
  position: relative;
  text-align: center;

  h4 {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 50px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
`

const MarketingAccept = styled.div`
  margin-top: 15px;
  text-align: left;
`

const Wrapper = styled.div`
  ${ContainerMixin};

  background-color: #f5f9f6;
  height: 100%;
  padding-top: 100px;
  position: relative;
  width: 100%;
  @media (max-width: 991px) {
    padding-top: 50px;
    padding-left: 7.885%;
    padding-right: 7.885%;
  }
`

const isValidEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const isValidNip = (nip) => {
  if (typeof nip !== 'string') {
    return false
  }

  nip = nip.replace(/[\ \-]/gi, '')

  let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7]
  let sum = 0
  let controlNumber = parseInt(nip.substring(9, 10))
  let weightCount = weight.length
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i]
  }

  return sum % 11 === controlNumber
}

const isValidPostalCode = (code) => {
  return code.match(/^\d\d-\d\d\d$/)
}

const CheckboxWrapper = styled.label`
  cursor: pointer;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 25px;
  user-select: none;
  text-align: left;

  ${(props) =>
    props.error
      ? `
    color: #ff122c;
  `
      : ``}

  &:hover {
    input ~ .checkmark {
      background-color: #e1e1e1;
    }
  }

  a {
    text-decoration: underline;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: ${(props) => props.theme.colorGreen};
      background-image: url(${checkedIcon});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  small {
    font-size: 16px;
    margin-top: -12px;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 1px solid
      ${(props) => (props.error ? `#ff122c;` : props.theme.colorGreen)};
  }

  .label {
    font-size: 18px;
    line-height: 19px;
  }
`

const Checkbox = ({ label, value, onChange, checked, error }) => (
  <CheckboxWrapper error={error === ''}>
    <input
      type="checkbox"
      value={value}
      onChange={onChange}
      checked={checked}
    />
    <span className="checkmark" />
    <span className="label">{label}</span>
  </CheckboxWrapper>
)

export default ({ onSubmit }) => {
  useEffect(() => {
    firebase.functions = firebase.app().functions('europe-west3')
  }, [])

  return (
    <Wrapper>
      <FormWrapper>
        <Row>
          <h4>
            Aby otrzymać przesyłkę wpisz swoje dane w odpowiednich polach:
          </h4>
        </Row>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            companyName: '',
            nip: '',
            street: '',
            postalCode: '',
            city: '',
            email: '',
            phone: '',
            tos1: false,
            tos2: false,
            tos3: false,
          }}
          validate={async (values) => {
            const errors = {}

            if (!values.firstName) errors.firstName = 'To pole jest wymagane'
            if (!values.lastName) errors.lastName = 'To pole jest wymagane'
            if (!values.companyName)
              errors.companyName = 'To pole jest wymagane'
            if (!values.nip) errors.nip = 'To pole jest wymagane'
            if (!values.street) errors.street = 'To pole jest wymagane'
            if (!values.postalCode) errors.postalCode = 'To pole jest wymagane'
            if (!values.city) errors.city = 'To pole jest wymagane'
            if (!values.email) errors.email = 'To pole jest wymagane'
            if (!values.phone) errors.phone = 'To pole jest wymagane'
            if (!values.tos1) errors.tos1 = ''

            if (values.nip && !isValidNip(values.nip)) {
              errors.nip = 'Podaj poprawny numer NIP'
            }

            if (values.nip && isValidNip(values.nip)) {
              const checkNip = firebase.functions.httpsCallable('checkNip')
              const checkNipResult = await checkNip({ nip: values.nip })
              if (!checkNipResult.data) {
                errors.nip =
                  'Niestety, dla tego numeru NIP limit nagród został już wyczerpany.'
              }
            }

            if (values.email && !isValidEmail(values.email)) {
              errors.email = 'Podaj poprawny adres e-mail'
            }

            if (values.postalCode && !isValidPostalCode(values.postalCode)) {
              errors.postalCode = 'Podaj kod pocztowy w formacie XX-XXX'
            }

            return errors
          }}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values)
            resetForm()
          }}
        >
          {({ errors, touched, values, setFieldValue, isSubmitting }) => (
            <Form>
              <Row>
                <FormColField>
                  <Field
                    id="firstName"
                    name="firstName"
                    placeholder="Imię"
                    className={
                      touched.firstName && errors.firstName ? `error` : ``
                    }
                  />
                  {touched.firstName && errors.firstName && (
                    <Error>{errors.firstName}</Error>
                  )}
                </FormColField>
                <FormColField>
                  <Field
                    id="lastName"
                    name="lastName"
                    placeholder="Nazwisko"
                    className={
                      touched.lastName && errors.lastName ? `error` : ``
                    }
                  />
                  {touched.lastName && errors.lastName && (
                    <Error>{errors.lastName}</Error>
                  )}
                </FormColField>
              </Row>
              <Row>
                <FormColField>
                  <Field
                    id="email"
                    name="email"
                    placeholder="E-mail"
                    className={touched.email && errors.email ? `error` : ``}
                  />
                  {touched.email && errors.email && (
                    <Error>{errors.email}</Error>
                  )}
                </FormColField>
                <FormColField>
                  <Field
                    id="phone"
                    name="phone"
                    placeholder="Numer telefonu"
                    className={touched.phone && errors.phone ? `error` : ``}
                  />
                  {touched.phone && errors.phone && (
                    <Error>{errors.phone}</Error>
                  )}
                </FormColField>
              </Row>
              <Row>
                <FormColField>
                  <Field
                    id="companyName"
                    name="companyName"
                    placeholder="Firma*"
                    className={
                      touched.companyName && errors.companyName ? `error` : ``
                    }
                  />
                  {touched.companyName && errors.companyName && (
                    <Error>{errors.companyName}</Error>
                  )}
                  <p>
                    *Akcja Wielka Gra jest przeznaczona dla uczestników, którzy
                    spełniają następujące warunki: są przedsiębiorcą w
                    rozumieniu art. 4 ustawy z dnia 6 marca 2018 r. Prawo
                    Przedsiębiorców (Dz. U. z 2019 r., poz. 1292) oraz w ramach
                    prowadzonej działalności gospodarczej prowadzą warsztat
                    samochodowy lub świadczą inne usługi związane z naprawą lub
                    konserwacją pojazdów.
                  </p>
                </FormColField>
              </Row>
              <Row>
                <FormColField>
                  <Field
                    id="nip"
                    name="nip"
                    placeholder="NIP"
                    className={touched.nip && errors.nip && `error`}
                  />
                  {touched.nip && errors.nip && <Error>{errors.nip}</Error>}
                </FormColField>
                <FormColField>
                  <Field
                    id="street"
                    name="street"
                    placeholder="Ulica, nr lokalu"
                    className={touched.street && errors.street && `error`}
                  />
                  {touched.street && errors.street && (
                    <Error>{errors.street}</Error>
                  )}
                </FormColField>
              </Row>
              <Row>
                <FormColField>
                  <Field
                    id="postalCode"
                    name="postalCode"
                    placeholder="Kod pocztowy (XX-XXX)"
                    className={
                      touched.postalCode && errors.postalCode && `error`
                    }
                  />
                  {touched.postalCode && errors.postalCode && (
                    <Error>{errors.postalCode}</Error>
                  )}
                </FormColField>
                <FormColField>
                  <Field
                    id="city"
                    name="city"
                    placeholder="Miejscowość"
                    className={touched.city && errors.city && `error`}
                  />
                  {touched.city && errors.city && <Error>{errors.city}</Error>}
                </FormColField>
              </Row>
              <Row>
                <FormColField>
                  <Field
                    name="tos1"
                    label={
                      <>
                        Oświadczam, że zapoznałem/am się z{' '}
                        <a href="/regulamin-akcji.pdf" target="_blank">
                          Regulaminem Akcji
                        </a>{' '}
                        i akceptuję jego postanowienia.
                        <br />
                        <small>
                          Wyrażenie zgody jest konieczne w celu przejścia do
                          dalszej części.
                        </small>
                      </>
                    }
                    component={Checkbox}
                    checked={values.tos1}
                    onChange={() => setFieldValue('tos1', !values.tos1)}
                    error={touched.tos1 && errors.tos1}
                  />
                  {touched.tos1 && errors.tos1 && <Error>{errors.tos1}</Error>}
                </FormColField>
              </Row>
              <Row>
                <FormColField>
                  <Field
                    name="tos2"
                    label="Tak, wyrażam zgodę na otrzymywanie informacji i reklam od firmy Schaeffler Polska sp. z o.o. i innych przedsiębiorstw grupy Schaeffler drogą mailową na podany przeze mnie adres e-mail."
                    component={Checkbox}
                    checked={values.tos2}
                    onChange={() => setFieldValue('tos2', !values.tos2)}
                    error={touched.tos2 && errors.tos2}
                  />
                  {touched.tos2 && errors.tos2 && <Error>{errors.tos2}</Error>}
                </FormColField>
              </Row>
              <Row>
                <FormColField>
                  <Field
                    name="tos3"
                    label="Tak, wyrażam zgodę na otrzymywanie informacji i reklam od firmy Schaeffler Polska sp. z o.o. i innych przedsiębiorstw grupy Schaeffler drogą telefoniczną, w tym m.in. poprzez wiadomość SMS lub usługi Instant Messenger, np. WhatsApp."
                    component={Checkbox}
                    checked={values.tos3}
                    onChange={() => setFieldValue('tos3', !values.tos3)}
                    error={touched.tos3 && errors.tos3}
                  />
                  {touched.tos3 && errors.tos3 && <Error>{errors.tos3}</Error>}

                  <MarketingAccept>
                    Zgoda na otrzymywanie informacji i reklam oznacza, że udostępnione dane osobowe mogą na podstawie art. 6 ust. 1 lit. a) Rozporządzenia o Ochronie Danych Osobowych być przetwarzane i wykorzystywane do momentu odwołania uprzednio udzielonej zgody lub ustania celu, dla którego zostały pozyskane, w zależności od tego, które zdarzenie nastąpi wcześniej, przez firmę Schaeffler Polska sp. z o.o. (administrator danych w rozumieniu Rozporządzenia o Ochronie Danych Osobowych), Schaeffler AG oraz spółki, w których firma Schaeffler AG posiada większość udziałów w sposób bezpośredni lub pośredni („Schaeffler”) do celów informacyjnych i reklamowych (wysyłanie newsletterów, zaproszeń na targi i wydarzenia, informacji o produktach, usługach, ofertach i działaniach firmy Schaeffler włącznie z wysyłaniem ankiet i komunikatów firmowych) drogą mailową i telefoniczną, poprzez wiadomość SMS i usługi Instant Messenger np. WhatsApp. Firma Schaeffler może uwzględnić w tym celu również podmiot zewnętrzny (usługodawcę), który świadczy usługi na rzecz Schaeffler i z którym zawarła umowę powierzenia przetwarzania danych na podstawie której może przekazywać dane osobowe ww. wspomnianemu podmiotowi. Nie dochodzi do przekazywania danych osobowych innym podmiotom. Pan / Pani może w każdej chwili odwołać udzieloną zgodę ze skutkiem na przyszłość poprzez wysłanie e-maila na adres: info@repxpert.pl. Odwołanie udzielonej zgody nie ma wpływu na legalność przetwarzania danych, które odbywało się na podstawie udzielonej zgody aż do momentu jej odwołania. Więcej informacji o przetwarzaniu danych osobowych znajduje się w Polityce prywatności na portalu REPXPERT firmy Schaeffler.
                  </MarketingAccept>
                </FormColField>
              </Row>
              <Row>
                <FormColField>
                  <button type="submit" disabled={isSubmitting}>
                    Wyślij
                  </button>
                </FormColField>
              </Row>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Wrapper>
  )
}
