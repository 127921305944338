import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Action = styled.div`
  background-color: #fff;
  color: ${(props) => props.theme.colorGreen};
  height: 170px;
  padding-left: 20px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 27%;
  z-index: 10;
  @media (min-width: 768px) and (max-width: 991px) {
    height: 122px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    height: 122px;
  }
  @media (max-width: 575px) {
    position: static;
    width: 100%;
    height: 118px;
    background-color: transparent;
    padding-left: 0;
  }

  a {
    cursor: pointer;
    display: inline-block;
    font-family: 'Righteous', cursive;
    font-size: 56px;
    letter-spacing: 5px;
    line-height: 170px;
    padding-left: 80px;
    position: relative;
    text-transform: uppercase;
    transition: ease 0.2s all;
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 32px;
      line-height: 122px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 20px;
      line-height: 122px;
    }
    @media (max-width: 575px) {
      display: inline-block;
      font-size: 20px;
      line-height: 118px;
      padding-left: 45px;
      margin-top: 60px;
    }

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      left: 0;
      border-top: 55px solid transparent;
      border-bottom: 55px solid transparent;
      border-left: 55px solid #000;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: ease 0.2s all;
      @media (max-width: 575px) {
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-left: 30px solid #000;
      }
    }

    &:hover {
      letter-spacing: 2.5px;

      &:before {
        left: 10px;
      }
    }
  }
`

const Description = styled.div`
  background-color: ${(props) => props.theme.colorGreen};
  color: #fff;
  font-size: 23px;
  font-weight: bold;
  height: 170px;
  left: 0;
  line-height: 1.05em;
  padding: 11px 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24%;
  z-index: 100;
  @media (min-width: 768px) and (max-width: 991px) {
    height: 122px;
    font-size: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 34%;
    height: 122px;
    font-size: 15px;
  }
  @media (max-width: 575px) {
    position: static;
    width: 85%;
    font-size: 15px;
    height: 118px;
    margin-top: 130px;
    margin-bottom: -15px;
  }

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 85px solid transparent;
    border-bottom: 85px solid transparent;
    border-left: 55px solid ${(props) => props.theme.colorGreen};
    position: absolute;
    left: 100%;
    top: 0;
    @media (min-width: 768px) and (max-width: 991px) {
      border-top: 60px solid transparent;
      border-bottom: 60px solid transparent;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      border-top: 60px solid transparent;
      border-bottom: 60px solid transparent;
    }
    @media (max-width: 575px) {
      border-top: 58px solid transparent;
      border-bottom: 58px solid transparent;
    }
  }

  &:before {
    background-color: ${(props) => props.theme.colorGreen};
    content: '';
    display: block;
    height: 170px;
    position: absolute;
    left: -500%;
    top: 0;
    width: 500%;
    @media (min-width: 768px) and (max-width: 991px) {
      height: 122px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      height: 122px;
    }
    @media (max-width: 575px) {
      height: 118px;
    }
  }
`

const RingsWrapper = styled.div`
  margin: 0 auto;
  max-width: 80%;
  position: relative;

  .placeholder {
    opacity: 0;
    pointer-events: none;
  }
`

const RingsImageWrapper = styled.div``

const Ring1Wrapper = styled.div`
  animation: spinLeft 480s linear infinite;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform-origin: 0 0;
  width: 100%;
  transform: translateX(-50%) translateY(-50%);
`

const Ring2Wrapper = styled.div`
  background-color: #fff;
  animation: spin 120s linear infinite;
  border-radius: 50%;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform-origin: 0 0;
  width: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 20;
`

const Ring3Wrapper = styled.div`
  animation: spinLeft 360s linear infinite;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform-origin: 0 0;
  width: 40%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 50;
`

const Wrapper = styled.div`
  background-color: #fff;
  margin-top: -10%;

  @keyframes spin {
    from {
      transform: rotate(0deg) translateX(-50%) translateY(-50%);
    }
    to {
      transform: rotate(360deg) translateX(-50%) translateY(-50%);
    }
  }

  @keyframes spinLeft {
    from {
      transform: rotate(360deg) translateX(-50%) translateY(-50%);
    }
    to {
      transform: rotate(0deg) translateX(-50%) translateY(-50%);
    }
  }
`

export default ({ onSubmit, stepExecution }) => {
  const data = useStaticQuery(graphql`
    query {
      Ring1: file(relativePath: { eq: "contest/step1-ring1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1050) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      Ring2: file(relativePath: { eq: "contest/step1-ring2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1050) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      Ring3: file(relativePath: { eq: "contest/step1-ring3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1050) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <RingsWrapper>
        {stepExecution && (
          <Description>
            Liczy się refleks!
            <br />
            Pokaż na co Cię stać,
            <br />
            podejmij wyzwanie, <br />
            odpowiedz na pytanie <br />
            konkursowe i wygraj <br />
            jedną z 3000 nagród!
          </Description>
        )}

        <RingsImageWrapper>
          <Img
            fluid={data.Ring1.childImageSharp.fluid}
            className="placeholder"
          />
        </RingsImageWrapper>

        <Ring1Wrapper>
          <Img fluid={data.Ring1.childImageSharp.fluid} />
        </Ring1Wrapper>

        <Ring2Wrapper>
          <Img fluid={data.Ring2.childImageSharp.fluid} />
        </Ring2Wrapper>

        <Ring3Wrapper>
          <Img fluid={data.Ring3.childImageSharp.fluid} />
        </Ring3Wrapper>

        <Action>
          <a onClick={onSubmit} id="step1-Action">Gram</a>
        </Action>
      </RingsWrapper>
    </Wrapper>
  )
}
