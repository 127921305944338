import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { ColMixin, ContainerMixin, RowMixin } from '../elements'

const FormWrapper = styled.div`
  margin: 0 auto;
  max-width: 30%;
  position: relative;
  top: 50%;

  @media (max-width: 991px) {
    position: static;
    max-width: 50%;
    transform: none;
  }
  @media (max-width: 991px) {
    max-width: 70%;
  }

  p {
    font-size: 23px;
    margin-bottom: 10px;
    text-transform: none;
  }
`

const Row = styled.div`
  ${RowMixin};

  font-size: 26px;
  margin-top: 15px;
  @media (max-width: 991px) {
    font-size: 18px;
  }
`

const Col = styled.div`
  ${ColMixin};

  text-align: center;
`

const Wrapper = styled.div`
  ${ContainerMixin};

  background-color: #f5f9f6;
  height: 100%;
  padding-top: 100px;
  position: relative;
  width: 100%;
  padding-bottom: 60px;
  @media (max-width: 991px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

export default ({ onSubmit }) => {
  const data = useStaticQuery(graphql`
    query {
      Ring1: file(relativePath: { eq: "contest/step5.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <FormWrapper>
        <Img fluid={data.Ring1.childImageSharp.fluid} />
        <Row>
          <Col>
            <p>Rejestracja już prawie zakończona. kliknij link potwierdzający w mailu, który właśnie od nas otrzymałeś.</p>
            <p>Pozdrawiamy<br/>Zespół Schaeffler REPXPERT</p>
          </Col>
        </Row>
      </FormWrapper>
    </Wrapper>
  )
}
