import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link as LinkScroll } from 'react-scroll'

import {
  ContainerMixin,
  PaddingsContainerMixin,
  RowMixin,
  ColMixin,
  DescMixin,
} from './elements'

import LineImage from '../images/rules-line.svg'

const Button = styled(LinkScroll)`
  background-color: ${(props) => props.theme.colorGreen};
  border-radius: 50%;
  box-shadow: 2px 10px 8px rgba(0, 0, 0, 0.25);
  color: #fff;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  width: 31.5%;
  height: 30%;
  position: absolute;
  top: 50%;
  text-align: center;
  left: 50%;
  transition: ease 0.2s all;
  transform: translateX(-44%) translateY(-50%);
  transform-origin: 0 50%;

  &:hover {
    transform: translateX(-50%) translateY(-50%) scale(1.15);
  }

  span {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
  }
`

const AwardsWrapper = styled.div`
  background-color: #efefef;
  overflow: hidden;
  position: relative;
  z-index: 100;
  padding-top: 60px;

  @keyframes rulesSpin {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`

const AwardsContainer = styled.div`
  ${ContainerMixin}
`

const AwardsRow = styled.div`
  ${RowMixin}
`

const AwardsCol = styled.div`
  ${ColMixin}
`

const AwardsColDesc = styled(AwardsCol)`
  flex: 0 0 ${(props) => props.size};
  max-width: ${(props) => props.size};
  padding-left: 10.51%;
  padding-right: 5.257%;
  ${DescMixin}
  @media (min-width: 1200px) and (max-width: 1420px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  /* @media (min-width: 992px) and (max-width: 1199px) {
    flex: 0 0 50%;
    max-width: 50%;
  } */
  @media (max-width: 1199px) {
    ${PaddingsContainerMixin}
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const AwardsDescWrapper = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 991px) {
    text-align: center;
    padding-bottom: 30px;
  }
  h3,
  p {
    position: relative;
    z-index: 20;
    @media (max-width: 991px) {
      text-align: left;
    }
  }
  h3 {
    position: relative;
    display: inline-block;
    z-index: 20;
    @media (min-width: 1600px) {
      padding-top: 12px;
      margin-bottom: 45px;
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      padding-top: 10px;
      margin-bottom: 50px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      padding-top: 8px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding-top: 5px;
      margin-bottom: 35px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      padding-top: 40px;
      margin-bottom: 45px;
    }
    @media (max-width: 575px) {
      width: 100%;
      text-align: center;
      padding-top: 50px;
      padding-left: 0 !important;
      padding-right: 0;
      margin-bottom: 30px !important;
    }
    &:before {
      content: '';
      position: absolute;
      top: -20px;
      left: -80px;
      right: -50px;
      bottom: -40px;
      z-index: -10;
      @media (max-width: 575px) {
        content: none;
        left: 0;
        right: 0;
      }
    }
    &:after {
      @media (max-width: 575px) {
        content: none !important;
      }
    }
  }
  p {
    strong {
      span {
        color: ${(props) => props.theme.colorGreen};
      }
    }
  }
`

const AwardsDescInnerWrapper = styled.div`
  position: relative;
  padding-left: 15px;
  z-index: 10;

  @media (min-width: 1600px) {
    padding-left: 20px;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-left: 20px;
  }
  @media (max-width: 991px) {
    padding-left: 0;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }

  h3 {
    color: ${(props) => props.theme.colorGreen};
    font-size: 24px;
    margin-bottom: 100px;
    margin-top: 0;
    padding-left: 79px;
    position: relative;

    &:after {
      background-image: url(${LineImage});
      background-position: top left;
      background-repeat: no-repeat;
      background-size: 100% auto;
      content: '';
      display: block;
      height: 500px;
      left: 0%;
      position: absolute;
      width: 591px;
      top: 120%;
      z-index: 1;
      @media (max-width: 1199px) {
        top: auto;
        bottom: calc(100% - 50px);
        transform: scale(1, -1);
      }
      @media (max-width: 991px) {
        content: none;
      }
    }
  }

  ul {
    li {
      font-size: 24px;
      margin-bottom: 49px;
      padding-left: 79px;
      position: relative;
      @media (max-width: 575px) {
        font-size: 20px;
        padding-left: 60px;
        margin-bottom: 20px;
      }

      span {
        background-color: ${(props) => props.theme.colorGreen};
        border-radius: 50%;
        color: #fff;
        display: block;
        font-weight: bold;
        height: 49px;
        left: 0;
        position: absolute;
        width: 49px;
        text-align: center;
        top: 0;
        @media (max-width: 575px) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
`

const AwardsColDescImage = styled(AwardsCol)`
  padding-left: 0;
  padding-right: 0;

  @media (max-width: 1199px) {
    order: -1;
    flex: 0 0 60%;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 50px;
  }

  @media (max-width: 575px) {
    overflow: hidden;
    padding-left: 7.885%;
    padding-right: 7.885%;
  }

  .gatsby-image-wrapper {
    animation: rulesSpin 200s linear infinite;
    margin-right: -5%;
    @media (max-width: 575px) {
      margin-right: auto;
    }
  }
`

const AwardsArrowDivider = styled.div`
  width: 100%;
  height: 80px;
  background-size: contain;
  margin-top: -80px;
  margin-bottom: 100px;
  @media (max-width: 991px) {
    height: 20px;
    background-image: none;
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (min-width: 576px) and (max-width: 991px) {
    height: 10px;
  }
  @media (max-width: 575px) {
    height: 1px;
  }
`

const Awards = ({ onClick }) => {
  const data = useStaticQuery(graphql`
    query {
      awardsImage: file(relativePath: { eq: "rules-dkz.png" }) {
        childImageSharp {
          fluid(maxWidth: 1050) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <AwardsWrapper id="rules">
      <AwardsContainer>
        <AwardsRow>
          <AwardsColDesc size="40%">
            <AwardsDescWrapper>
              <AwardsDescInnerWrapper type="main">
                <h3>Zasady</h3>
                <ul>
                  <li>
                    <span>1.</span> Kliknij przycisk{' '}
                    <strong>"biorę udział w{'\u00A0'}grze"</strong>, który
                    znajdziesz obok
                  </li>
                  <li>
                    <span>2.</span> Odpowiedz na{'\u00A0'}
                    <strong>pytanie konkursowe</strong>
                  </li>
                  <li>
                    <span>3.</span> <strong>Sprawdź swój wynik</strong>, podaj
                    dane do{'\u00A0'}wysyłki, i{'\u00A0'}poczekaj na{'\u00A0'}
                    paczkę!
                  </li>
                  <li>
                    <span>4.</span>{' '}
                    <strong>
                      Pamiętaj, aby wypełnić wszystkie pola Formularza
                      Konkursowego poprawnie
                    </strong>{' '}
                    – w innym przypadku nie będziemy mogli do Ciebie wysłać
                    Twojej nagrody!
                  </li>
                </ul>
              </AwardsDescInnerWrapper>
            </AwardsDescWrapper>
          </AwardsColDesc>
          <AwardsColDescImage size="60%">
            <Img fluid={data.awardsImage.childImageSharp.fluid} />

            <Button
                to="contest"
                href="#contest"
                onClick={onClick}
                smooth
                id="rules-Button"
            >
              <span>biorę udział w grze!</span>
            </Button>
          </AwardsColDescImage>
          <AwardsArrowDivider />
        </AwardsRow>
      </AwardsContainer>
    </AwardsWrapper>
  )
}

export default Awards
