import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import ErrorIcon from '../../images/contest/error-icon.png'

import { ColMixin, ContainerMixin, RowMixin } from '../elements'

const CheckboxWrapper = styled.label`
  cursor: pointer;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 25px;
  user-select: none;

  &:hover {
    input ~ .checkmark {
      background-color: #e1e1e1;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    @media (max-width: 575px) {
      position: static;
    }

    &:checked ~ .checkmark {
      background-color: ${(props) => props.theme.colorGreen};
    }
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 1px solid ${(props) => props.theme.colorGreen};
    border-radius: 50%;
    @media (max-width: 575px) {
      /* position: static; */
    }
  }
`

const Checkbox = ({
  label,
  value,
  onMouseEnter,
  onMouseLeave,
  onChange,
  checked,
}) => (
  <CheckboxWrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
    <input
      type="checkbox"
      value={value}
      onChange={onChange}
      checked={checked}
    />
    <span className="checkmark" />
    <span className="label">{label}</span>
  </CheckboxWrapper>
)

const ColLeft = styled.div`
  ${ColMixin};
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
    height: 100%;
  }
`

const ColRight = styled.div`
  ${ColMixin};
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
    height: 100%;
  }

  form {
    display: block;
    left: 0;
    padding-bottom: 25px;
    padding-left: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 991px) {
      position: static;
      transform: none;
    }

    button {
      border: 1px solid ${(props) => props.theme.colorGreen};
      color: ${(props) => props.theme.colorGreen};
      cursor: pointer;
      border-radius: 30px;
      display: inline-block;
      font-size: 25px;
      font-weight: bold;
      line-height: 25px;
      padding: 15px 35px;
      position: relative;
      transition: ease 0.2s all;

      &:hover {
        background-color: ${(props) => props.theme.colorGreen};
        color: #fff;
      }
    }

    p {
      font-size: 25px;
      font-weight: bold;

      small {
        font-size: 20px;
        font-weight: normal;
      }
    }
  }
`

const Description = styled.div`
  background-color: ${(props) => props.theme.colorGreen};
  border-radius: 50%;
  color: #fff;
  height: 48%;
  font-weight: bold;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%) translateX(-56%);
  transition: transform 0.2s ease;
  width: 47%;

  &.error {
    background-color: red;
    transition: background-color 1000ms linear;
  }

  h4 {
    font-family: 'Righteous', cursive;
    font-size: 30px;
    font-weight: 400;
    margin: 0;
    letter-spacing: 5px;
    text-transform: uppercase;
    left: 15px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    @media (min-width: 1200px) and (max-width: 1399px) {
      font-size: 24px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 20px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 10px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 6px;
    }
    @media (max-width: 575px) {
      font-size: 12px;
    }
    @media (max-width: 340px) {
      font-size: 9px;
    }
  }
`

const Error = styled.div`
  background-color: #ff122c;
  height: 150px;
  left: 40px;
  padding-left: 20px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  @media (max-width: 575px) {
    transform: none;
  }

  &:after {
    background-color: #ff122c;
    content: '';
    display: block;
    height: 150px;
    pointer-events: none;
    position: absolute;
    right: -300%;
    top: 50%;
    transform: translateY(-50%);
    width: 300%;
  }

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 75px solid transparent;
    border-bottom: 75px solid transparent;
    border-right: 55px solid #ff122c;
    position: absolute;
    top: 50%;
    left: -55px;
    transform: translateY(-50%);
  }

  a {
    color: #fff;
    cursor: pointer;
    display: inline-block;
    position: relative;

    &:hover {
      &:after {
        right: -35px;
      }
    }

    &:after {
      background-image: url(${ErrorIcon});
      background-size: 100% 100%;
      content: '';
      display: block;
      height: 25px;
      position: absolute;
      right: -30px;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.2s ease;
      width: 25px;
    }
  }

  p {
    color: #fff;
    font-family: 'Righteous', cursive;
    font-size: 26px;
    font-weight: 400;
    line-height: 1.1em;
    margin-bottom: 15px;
    padding-top: 15px;
    text-transform: uppercase;
  }
`

const Options = styled.div`
  font-size: 23px;
  font-weight: bold;
  margin: 45px 0 30px 0;
`

const Ring = styled.div`
  position: relative;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: 575px) {
    ${(props) =>
      props.execution &&
      `
      transform: none;
    `}
  }

  .gatsby-image-wrapper {
    transition: transform 0.2s ease;

    ${(props) =>
      props.checkbox === 'LuK'
        ? `
      transform: rotate(-5deg);
    `
        : ``}

    ${(props) =>
      props.checkbox === 'FAG'
        ? `
      transform: rotate(5deg);
    `
        : ``}
  }
`

const Row = styled.div`
  ${RowMixin};

  height: 100%;
`

const Wrapper = styled.div`
  ${ContainerMixin};

  background-color: #fff;
  height: 100%;
  position: relative;
  width: 100%;
  @media (max-width: 991px) {
    height: 100%;
  }
  @media (min-width: 576px) and (max-width: 991px) {
    padding-top: 50px;
  }
`

export default ({ onSubmit, stepExecution }) => {
  const data = useStaticQuery(graphql`
    query {
      Ring: file(relativePath: { eq: "contest/step2-ring.png" }) {
        childImageSharp {
          fluid(maxWidth: 920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const [checkboxHovered, setCheckboxHovered] = useState()
  const [error, setError] = useState(false)
  const [values, setValues] = useState([])

  const handleChange = (e) => {
    if (values.includes(e.currentTarget.value)) {
      setValues(values.filter((v) => v !== e.currentTarget.value))
    } else {
      setValues([...values, e.currentTarget.value])
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (values.length === 3) {
      onSubmit()
      setValues([])
    } else {
      setError(true)
    }
  }

  return (
    <Wrapper>
      <Row>
        <ColLeft>
          <Ring checkbox={checkboxHovered} execution={stepExecution}>
            <Img fluid={data.Ring.childImageSharp.fluid} />
            <Description>
              <h4>Twoje pytanie konkursowe:</h4>
            </Description>
          </Ring>
        </ColLeft>
        <ColRight>
          {!error && (
            <form onSubmit={handleSubmit}>
              <p>
                Która/e z poniższych marek
                <br />
                należą do firmy Schaeffler?
                <br />
                <small>(możesz zaznaczyć wiele odpowiedzi)</small>
              </p>
              <Options>
                <Checkbox
                  onMouseEnter={() => setCheckboxHovered('LuK')}
                  onMouseLeave={() => setCheckboxHovered(null)}
                  onChange={handleChange}
                  checked={values.includes('LuK')}
                  value="LuK"
                  label="LuK"
                />
                <Checkbox
                  onMouseEnter={() => setCheckboxHovered('INA')}
                  onMouseLeave={() => setCheckboxHovered(null)}
                  onChange={handleChange}
                  checked={values.includes('INA')}
                  value="INA"
                  label="INA"
                />
                <Checkbox
                  onMouseEnter={() => setCheckboxHovered('FAG')}
                  onMouseLeave={() => setCheckboxHovered(null)}
                  onChange={handleChange}
                  checked={values.includes('FAG')}
                  value="FAG"
                  label="FAG"
                />
              </Options>
              <button type="submit">Sprawdź</button>
            </form>
          )}

          {error && (
            <Error>
              <p>
                :( Niestety.
                <br />
                Twoja odpowiedź
                <br />
                jest niepoprawna.
              </p>
              <a onClick={() => {
                setError(false)
                setValues([])
              }}>Spróbuj jeszcze raz</a>
            </Error>
          )}
        </ColRight>
      </Row>
    </Wrapper>
  )
}
