import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link as LinkScroll } from 'react-scroll'

import {
  ContainerMixin,
  PaddingsContainerMixin,
  RowMixin,
  ColMixin,
  DescMixin,
  HeaderTitleWithLines,
} from './elements'

import LineImage from '../images/awards-line.svg'

const Button = styled(LinkScroll)`
  background-color: ${(props) => props.theme.colorGreen};
  border-radius: 30px;
  box-shadow: 2px 11px 8px rgba(0, 0, 0, 0.25);
  color: #fff;
  display: inline-block;
  font-size: 25px;
  font-weight: bold;
  line-height: 25px;
  padding: 15px 35px;
  position: relative;
  z-index: 10;

  &:after {
    background-image: url(${LineImage});
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 100% auto;
    bottom: 20px;
    content: '';
    display: block;
    height: 500px;
    left: 110%;
    position: absolute;
    width: 560px;
    z-index: 1;
    @media (max-width: 991px) {
      content: none;
    }
  }
`

const AwardsWrapper = styled.div`
  background-color: #fff;
  overflow: hidden;
  position: relative;
  z-index: 100;
  padding-top: 60px;
  @media (max-width: 575px) {
    padding-top: 35px;
  }

  @keyframes awardsSpin {
    from {
      transform: rotate(0deg) translateX(-50%) translateY(-50%);
    }
    to {
      transform: rotate(360deg) translateX(-50%) translateY(-50%);
    }
  }
`

const AwardsContainer = styled.div`
  ${ContainerMixin}
`

const AwardsRow = styled.div`
  ${RowMixin}
`

const AwardsCol = styled.div`
  ${ColMixin}
`

const AwardsColHeader = styled(AwardsCol)`
  /* overflow: hidden; */
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 100px;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-bottom: 40px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-bottom: 30px;
  }
  @media (max-width: 575px) {
    padding-bottom: 25px;
  }
`

const AwardsHeaderTitle = styled(HeaderTitleWithLines)``

const AwardsColDesc = styled(AwardsCol)`
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 10.51%;
  padding-right: 5.257%;
  ${DescMixin}  
  @media (max-width: 991px) {
    ${PaddingsContainerMixin}
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const AwardsDescWrapper = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 991px) {
    text-align: center;
    padding-bottom: 30px;
  }
  h3,
  p {
    position: relative;
    z-index: 20;
    @media (max-width: 991px) {
      text-align: left;
    }
  }
  h3 {
    position: relative;
    display: inline-block;
    z-index: 20;
    @media (min-width: 1600px) {
      padding-top: 12px;
      margin-bottom: 45px;
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      padding-top: 10px;
      margin-bottom: 50px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      padding-top: 8px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding-top: 5px;
      margin-bottom: 35px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      padding-top: 40px;
      margin-bottom: 45px;
    }
    @media (max-width: 575px) {
      width: 100%;
      text-align: center;
      padding-top: 50px;
      padding-left: 0 !important;
      padding-right: 0;
      margin-bottom: 30px !important;
    }
    &:before {
      content: '';
      position: absolute;
      top: -20px;
      left: -80px;
      right: -50px;
      bottom: -40px;
      z-index: -10;
      @media (max-width: 991px) {
        content: none;
        left: 0;
        right: 0;
      }
    }
    &:after {
      @media (max-width: 991px) {
        content: none !important;
      }
    }
  }
  p {
    strong {
      span {
        color: ${(props) => props.theme.colorGreen};
      }
    }
  }
`

const AwardsDescInnerWrapper = styled.div`
  position: relative;
  padding-left: 15px;
  z-index: 10;

  @media (min-width: 1600px) {
    padding-left: 20px;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-left: 20px;
  }
  @media (max-width: 991px) {
    padding-left: 0;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }
`

const AwardsColDescImage = styled(AwardsCol)`
  padding-left: 0;
  padding-right: 0;
  position: relative;

  @media (max-width: 991px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 50px;
  }
`

const ImgWrapper = styled.div`
  animation: awardsSpin 200s linear infinite;
  left: 50%;
  position: relative;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transform-origin: 0 0;
`

const AwardsHeaderComplectsQtyWrapper = styled.div`
  text-align: right;
  @media (max-width: 991px) {
    display: none;
    text-align: center;
  }
`

const AwardsHeaderComplectsQtyWrapperMobile = styled(
  AwardsHeaderComplectsQtyWrapper
)`
  margin-top: -40px;
  margin-bottom: 30px;
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 991px) {
    display: block;
  }
`

const AwardsHeaderComplectsQty = styled.div`
  position: relative;
  display: inline-block;
  /* color: #8f8f8f; */
  color: rgba(93, 93, 93, 0.8);
  /* font-family: 'ff-meta-correspondence-web-p', sans-serif; */
  font-size: 220px;
  font-weight: 700;
  font-style: normal;

  text-transform: uppercase;
  line-height: 1;
  padding-right: 30px;

  @media (min-width: 992px) {
    margin-top: -150px;
    ${(props) =>
      props.type === 'additional' &&
      `
      margin-top: -180px;
    `}
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 160px;
    padding-right: 32px;
    margin-top: -108px;
    ${(props) =>
      props.type === 'additional' &&
      `
      margin-top: -145px;
    `}
  }
  @media (max-width: 991px) {
    padding-right: 0;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 110px;
    padding-top: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 100px;
    padding-top: 20px;
  }
  @media (max-width: 575px) {
    font-size: 80px;
    padding-top: 15px;
  }
  span {
    display: inline-block;
    &:first-of-type {
      vertical-align: text-top;
    }
  }
  .qtyWrapper {
    display: inline-block;
  }
  .qtyText {
    right: -35px;
    bottom: 120px;
    font-family: ${(props) => props.theme.fontMetaPro};
    font-size: 22px;

    ${(props) =>
      props.type === 'main' &&
      `
      letter-spacing: 5px;
    `}
    ${(props) =>
      props.type === 'additional' &&
      `
      bottom: 120px;
      font-size: 22px;
    `}
    @media (min-width: 992px) {
      position: absolute;
      transform: rotate(-90deg);
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      right: -25px;
      bottom: 89px;
      font-size: 17px;
      ${(props) =>
        props.type === 'main' &&
        `
      bottom: 89px;
      font-size: 16px;
      letter-spacing: 5px;
    `}
      ${(props) =>
        props.type === 'additional' &&
        `
      font-size: 16px;
    `}
    }
    @media (max-width: 991px) {
      display: block;
      text-align: center;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 20px;
      margin-top: -15px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 18px;
      margin-top: -12px;
    }
    @media (max-width: 575px) {
      font-size: 16px;
      margin-top: -10px;
    }
  }
`

const ImgAwardsMore = styled(Img)`
  float: right;
  height: auto;
  margin-bottom: 120px;
  margin-right: 70px;
  width: 70%;

  @media (max-width: 992px) {
    float: none;
    margin-bottom: 0;
    margin-right: auto;
    margin-left: auto;
  }
`

const AwardsArrowDivider = styled.div`
  width: 100%;
  height: 80px;
  background-size: contain;
  margin-top: -80px;
  margin-bottom: 100px;
  @media (max-width: 991px) {
    height: 20px;
    background-image: none;
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (min-width: 576px) and (max-width: 991px) {
    height: 10px;
  }
  @media (max-width: 575px) {
    height: 1px;
  }
`

const Awards = () => {
  const data = useStaticQuery(graphql`
    query {
      awardsImage: file(relativePath: { eq: "awards-dkz.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <AwardsWrapper id="awards">
      <AwardsContainer>
        <AwardsRow>
          <AwardsColDesc>
            <AwardsDescWrapper>
              <AwardsDescInnerWrapper type="main">
                <p>
                  <strong>3000 nagród</strong> rzeczowych{' '}
                  <strong>
                    <br />o{'\u00A0'}wartości ponad 100.000 PLN
                  </strong>
                  <br />
                  czeka na{'\u00A0'}Ciebie!
                </p>
                <p>
                  Odpowiedz prawidłowo na{'\u00A0'}pytanie konkursowe i
                  {'\u00A0'}
                  <strong>WYBIERZ SWOJĄ ULUBIONĄ NAGRODĘ</strong> – do{'\u00A0'}
                  wyboru masz aż{'\u00A0'}8{'\u00A0'}różnych nagród!
                </p>
                <p>
                  Pamiętaj! Liczy się refleks – im{'\u00A0'}dłużej będziesz
                  zwlekał, tym mniejszy wybór – dlatego zagraj już dziś: do
                  {'\u00A0'}wygrania setki nagród, które przydadzą się w
                  {'\u00A0'}Twoim serwisie samochodowym – i{'\u00A0'}nie tylko!
                </p>
                <p>
                  <strong>Wygrana jest prostsza niż myślisz!</strong>
                </p>

                <Button to="rules" href="#rules" smooth>
                  dowiedz się więcej
                </Button>
              </AwardsDescInnerWrapper>
            </AwardsDescWrapper>
          </AwardsColDesc>
          <AwardsColDescImage>
            <ImgWrapper>
              <Img fluid={data.awardsImage.childImageSharp.fluid} />
            </ImgWrapper>
          </AwardsColDescImage>
          <AwardsArrowDivider />
        </AwardsRow>
      </AwardsContainer>
    </AwardsWrapper>
  )
}

export default Awards
