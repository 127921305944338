import React, { useState } from 'react'

import Layout from '../components/layout'
import Account from '../components/account'
import Awards from '../components/awards'
import Contacts from '../components/contacts'
import Contest from '../components/contest/contest'
import Footer from '../components/footer'
import Navbar from '../components/navbar'
import Rules from '../components/rules'

const IndexPage = (props) => {
  const [contestStep, setContestStep] = useState(1)

  return (
    <Layout title="Schaeffler Wielka Gra" description="">
      <Navbar />
      <Contest locationOrigin={props.location.origin} step={contestStep} />
      <Awards />
      <Rules onClick={() => setContestStep(2)} />
      <Account />
      <Contacts />
      <Footer />
    </Layout>
  )
}

export default IndexPage
