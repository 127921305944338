import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import firebase from 'gatsby-plugin-firebase'
import styled from 'styled-components'

import { ColMixin, ContainerMixin, RowMixin } from '../elements'

const Col = styled.div`
  ${ColMixin};
`

const ImgWrapper = styled.div`
  max-height: 100%;
  height: 240px;
  @media (max-width: 575px) {
    height: 190px;
  }
`

const Prize = styled.div`
  ${ColMixin};
  @media (max-width: 767px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  cursor: ${(props) => (props.isSold ? `not-allowed` : `pointer`)};
  opacity: 1;
  margin-top: 40px;
  max-width: 25%;
  transition: ease 0.2s all;

  ${(props) =>
    props.isSold
      ? `
    opacity: .5;
  `
      : ``}

  span {
    margin-top: 10px;
    display: block;
    text-align: center;

    small {
      color: #ff122c;
      display: block;
      font-size: 12px;
    }
  }

  .gatsby-image-wrapper {
    transition: ease 0.2s all;
  }

  &:hover {
    ${(props) =>
      !props.isSold
        ? `
      span {
        color: ${(props) => props.theme.colorGreen};
      }

      .gatsby-image-wrapper{
        transform: scale(1.1);
      }
    `
        : ``}
  }
`

const PrizeBreak = styled.div`
  flex-basis: 100%;
  width: 0;
`

const Prizes = styled.div`
  position: relative;
  top: 50%;
  /* transform: translateY(-50%); */
  @media (max-width: 1599px) {
    transform: none;
  }
`

const RowPrizes = styled.div`
  ${RowMixin};

  margin: 0 auto;
  max-width: 75%;
  @media (max-width: 575px) {
    max-width: 95%;
  }
`

const RowFirst = styled.div`
  ${RowMixin};
  text-align: center;

  h4 {
    font-family: 'Righteous', cursive;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 5px;
    margin: 0 0 10px 0;
    text-transform: uppercase;
  }

  p {
    font-size: 23px;
    line-height: 1.4em;

    strong {
      color: ${(props) => props.theme.colorGreen};
      font-weight: bold;
    }
  }
`

const Wrapper = styled.div`
  ${ContainerMixin};

  background-color: #fff;
  height: 100%;
  padding-top: 100px;
  position: relative;
  width: 100%;
  @media (max-width: 575px) {
    padding-top: 50px;
  }
`

const prizes = [
  {
    id: 'bottleYellow',
    name: 'żółty bidon z powłoką soft-touch',
    image: 'BottleYellow',
  },
  {
    id: 'chargerGreen',
    name:
      'wygodny zestaw do ładowania telefonów z systemem operacyjnym Android',
    image: 'ChargerGreen',
  },
  {
    id: 'fagCard',
    name: 'karta do sprawdzania pierścienia magnetycznego ABS w łożysku',
    image: 'FagCard',
  },
  {
    id: 'cable',
    name: 'przydatny rozgałęźnik do ładowania urządzeń ze złączami micro-USB, iOS, USB-C',
    image: 'Cable',
  },
  {
    id: 'screwdriver',
    name: 'podręczny śrubokręt w długopisie',
    image: 'Screwdriver',
  },
  {
    id: 'inaTool',
    name:
      'narzędzie do montażu szpilki napinacza paska rozrządu w silnikach VAG 1.9/2.0 TDi.',
    image: 'InaTool',
  },
  {
    id: 'bottleRed',
    name: 'czerwony bidon z powłoką soft-touch',
    image: 'BottleRed',
  },
  {
    id: 'bottleGreen',
    name: 'zielony bidon z powłoką soft-touch',
    image: 'BottleGreen',
  },
]

export default ({ onSubmit, stepExecution }) => {
  const imagesData = useStaticQuery(graphql`
    query {
      BottleGreen: file(
        relativePath: { eq: "contest/awards/bottleGreen.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 240) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      BottleRed: file(relativePath: { eq: "contest/awards/bottleRed.png" }) {
        childImageSharp {
          fluid(maxHeight: 240) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      BottleYellow: file(
        relativePath: { eq: "contest/awards/bottleYellow.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 240) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      Cable: file(relativePath: { eq: "contest/awards/cable.png" }) {
        childImageSharp {
          fluid(maxHeight: 240) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      ChargerGreen: file(
        relativePath: { eq: "contest/awards/chargerGreen.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 240) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      FagCard: file(relativePath: { eq: "contest/awards/rxCard.png" }) {
        childImageSharp {
          fluid(maxHeight: 240) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      InaTool: file(relativePath: { eq: "contest/awards/inaTool.png" }) {
        childImageSharp {
          fluid(maxHeight: 240) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      Screwdriver: file(
        relativePath: { eq: "contest/awards/screwdriver.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 240) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      BottleGreenGrayscale: file(
        relativePath: { eq: "contest/awards/bottleGreen.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 240, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      BottleRedGrayscale: file(
        relativePath: { eq: "contest/awards/bottleRed.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 240, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      BottleYellowGrayscale: file(
        relativePath: { eq: "contest/awards/bottleYellow.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 240, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      CableGrayscale: file(relativePath: { eq: "contest/awards/cable.png" }) {
        childImageSharp {
          fluid(maxHeight: 240, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      ChargerGreenGrayscale: file(
        relativePath: { eq: "contest/awards/chargerGreen.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 240, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      FagCardGrayscale: file(
        relativePath: { eq: "contest/awards/rxCard.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 240, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      InaToolGrayscale: file(
        relativePath: { eq: "contest/awards/inaTool.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 240, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      ScrewdriverGrayscale: file(
        relativePath: { eq: "contest/awards/screwdriver.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 240, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const [availablePrizes, setAvaialbePrizes] = useState([])
  useEffect(() => {
    const db = firebase.firestore()
    db.collection('prizes')
      .doc('za214gaD5uV06hC9go89')
      .onSnapshot((snapshot) => {
        const data = snapshot.data()
        setAvaialbePrizes(
          prizes.map((p) => {
            if (data[p.id] <= 0) {
              p.image = `${
                p.id.charAt(0).toUpperCase() + p.id.slice(1)
              }Grayscale`
              p.isSold = true
            } else {
              p.image = p.image.replace(/Grayscale/, ``)
              p.isSol = false
            }
            return p
          })
        )
      })
  }, [])

  return (
    <Wrapper>
      <Prizes>
        <RowFirst>
          <Col>
            <h4>GRATULACJE</h4>
            <p>
              <strong>wybierz swoją nagrodę klikając w nią</strong>, pozostaw swoje dane
              teleadresowe
              <br />
              oraz adres e-mail, nagroda dotrze do Ciebie już wkrótce!
            </p>
          </Col>
        </RowFirst>
        <RowPrizes>
          {availablePrizes.map((prize, prizeIdx) => (
            <>
              <Prize
                isSold={prize.isSold}
                onClick={() => (prize.isSold ? null : onSubmit(prize.id))}
                key={`prize-${prize.id}`}
              >
                <ImgWrapper>
                  {typeof imagesData[prize.image] !== 'undefined' && (
                    <Img
                      style={{ maxHeight: '100%' }}
                      imgStyle={{ objectFit: 'contain' }}
                      fluid={imagesData[prize.image].childImageSharp.fluid}
                      alt={prize.name}
                    />
                  )}
                </ImgWrapper>
                <span>
                  {prize.name}
                  {prize.isSold && <small>już wyczerpane</small>}
                </span>
              </Prize>
              {(prizeIdx + 1) % 4 === 0 && <PrizeBreak />}
            </>
          ))}
        </RowPrizes>
      </Prizes>
    </Wrapper>
  )
}
