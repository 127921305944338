import React, { useEffect, useState } from 'react'
import firebase from 'gatsby-plugin-firebase'
import { Helmet } from 'react-helmet'
import { Transition } from 'react-transition-group'
import styled from 'styled-components'
import * as Scroll from 'react-scroll'

import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import Step4 from './step4'
import Step5 from './step5'

const emailText = `
Gratulacje!

Nagroda jest już Twoja – poinformujemy Ciebie, kiedy zostanie do Ciebie wysłana.
Zanim jednak do Ciebie dotrze, nie trać czasu! Odkryj praktyczny portal dla swojego warsztatu, korzystaj ze Sklepu Bonusowego i wygrywaj cenne nagrody w Konkursach! Jeżeli tego jeszcze nie zrobiłeś – zarejestruj się jeszcze dziś!

REPXPERT oferuje wszystko, co potrzebne, by ułatwić życie niezależnego mechanika. Dobór części, informacje o produktach do silnika, zawieszenia czy przeniesienia napędu, szczegółowe wskazówki montażu - REPXPERT ma to wszystko. Eksperci dla fachowców.
REPXPERT to także telefoniczne wsparcie dla mechaników i nielimitowany dostęp do pomocy naszych ekspertów technicznych - zupełnie za darmo!

To jeszcze nie wszystko! REPXPERT to także szkolenia na żywo, na których zyskasz wiedzę aby być najlepszym w swojej branży. Zapisz się na nasz newsletter aby być zawsze na bieżąco!

Jesteśmy w kontakcie!

Twój Zespół Schaeffler REPXPERT
repxpert.pl
`

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  .step2Wrapper,
  .step3Wrapper,
  .step4Wrapper,
  .step5Wrapper {
    /* bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0; */
    transition: ease 0.2s all;

    &.exited {
      display: none;
      pointer-events: none;
    }
  }

  .step1Wrapper {
    z-index: 100;
    &.exited {
      display: none;
      pointer-events: none;
      transition: ease 0.1s all;
    }
  }

  .step2Wrapper {
    z-index: 80;
    position: static;
    transition: ease-in   0.8s all;
    &.entering {
      transform: scale(10);
      transition: ease-in 0.4s all;
    }
    &.entered {
      transform: scale(1);
    }
  }

  .step3Wrapper {
    z-index: 70;
    visibility: hidden;
    transition: visibility 1s, opacity 1s linear;
    transition: ease 1s all;
    &.exited {
      /* display: block!important; */
      visibility: hidden;
    }
    &.entered {
      position: static;
      visibility: visible;
    }
  }

  .step4Wrapper {
    z-index: 60;
    position: static;
    &.entered {
    }
    &.entered ~ .step3Wrapper {
      display: none;
    }
  }

  .step5Wrapper {
    &.entered {
      position: static;
    }
  }
`

const ContestTransition = styled(Transition)``

export default ({ locationOrigin, step }) => {
  const [step1In, setStep1In] = useState(step === 1)
  const [step2In, setStep2In] = useState(step === 2)
  const [step3In, setStep3In] = useState(false)
  const [step4In, setStep4In] = useState(false)
  const [step5In, setStep5In] = useState(false)

  const [prize, setPrize] = useState()
  const [data, setData] = useState()

  let scroll = Scroll.animateScroll

  useEffect(() => {
    if (step === 1) {
      setStep1In(true)
      setStep2In(false)
    }

    if (step === 2) {
      setStep1In(false)
      setStep2In(true)
    }

    setStep3In(false)
    setStep4In(false)
    setStep5In(false)
  }, [step])

  const handleStep1Submit = () => {
    setStep1In(false)
    setStep2In(true)
    scroll.scrollToTop()
  }

  const handleStep2Submit = () => {
    setStep2In(false)
    setStep3In(true)
    scroll.scrollToTop()
  }

  const handleStep3Submit = (value) => {
    setPrize(value)
    setStep3In(false)
    setStep4In(true)
    scroll.scrollToTop()
  }

  const handleStep4Submit = (values) => {
    setData(values)

    scroll.scrollToTop()

    const getEmailText = (key) => `
      Gratulacje!

      Nagroda jest już prawie Twoja – przed Tobą ostatni krok: POTWIERDŹ REJESTRACJĘ I SWÓJ UDZIAŁ W AKCJI poprzez kliknięcie w link:
      ${locationOrigin}/confirm?key=${key}

      Jak tylko zweryfikujemy poprawność podanych danych poinformujemy Cię kiedy przesyłka zostanie wysłana.

      Jesteśmy w kontakcie!

      Twój Zespół Schaeffler REPXPERT
      repxpert.pl
    `

    const getEmailTextHtml = (key) => `
    <h3>Gratulacje!</h3>

    <p>Nagroda jest już prawie Twoja – przed Tobą ostatni krok: POTWIERDŹ REJESTRACJĘ I SWÓJ UDZIAŁ W AKCJI poprzez kliknięcie w link:<br/>
    <a href="${locationOrigin}/confirm?key=${key}">${locationOrigin}/confirm?key=${key}</a></p>

    <p>Jak tylko zweryfikujemy poprawność podanych danych poinformujemy Cię kiedy przesyłka zostanie wysłana.</p>
    <p>Jesteśmy w kontakcie!</p>
    <p>Twój Zespół Schaeffler REPXPERT<br/><a href="https://repxpert.pl">repxpert.pl</a></p>
    `

    const db = firebase.firestore()
    db.collection('prizes')
      .doc('za214gaD5uV06hC9go89')
      .update(prize, firebase.firestore.FieldValue.increment(-1))
      .then(() => {
        db.collection('submissions')
          .add({
            prize: prize,
            createdAt: new Date(),
            ...values,
          })
          .then((docRef) => {
            db.collection('mails')
              .add({
                to: [values.email],
                from: 'Schaeffler REPXPERT <no-reply@wielka-gra.pl>',
                message: {
                  subject: `Schaeffler Wielka Gra - potwierdzenie rejestracji`,
                  text: getEmailText(docRef.id),
                  html: getEmailTextHtml(docRef.id),
                },
              })
              .then(() => {
                setStep4In(false)
                setStep5In(true)
              })
          })
      })
  }

  const step1TransitionStyles = {
    entering: {},
    entered: {},
    exiting: {
      opacity: 0,
      transition: `ease 0.8s all`,
      transform: `scale(10)`,
    },
    exited: {
      opacity: 0,
    },
  }

  const step2TransitionStyles = {
    entering: {
      opacity: 0,
      transform: `scale(10)`,
    },
    entered: {
      opacity: 1,
      transform: null,
    },
    exiting: {
      opacity: 0,
    },
    exited: {
      opacity: 0,
    },
  }

  const step3TransitionStyles = {
    entering: {
      opacity: 0,
    },
    entered: {
      opacity: 1,
      transform: null,
    },
    exiting: {
      opacity: 0,
      transition: `ease 0.8s all`,
      transform: `scale(5)`,
    },
    exited: {
      opacity: 0,
    },
  }

  const step4TransitionStyles = {
    entering: {
      opacity: 0,
    },
    entered: {
      opacity: 1,
      transform: null,
    },
    exiting: {
      opacity: 0,
    },
    exited: {
      opacity: 0,
    },
  }

  const step5TransitionStyles = {
    entering: {
      opacity: 0,
    },
    entered: {
      opacity: 1,
      transform: null,
    },
    exiting: {
      opacity: 0,
    },
    exited: {
      opacity: 0,
    },
  }

  return (
    <Wrapper id="contest">
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Righteous&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <ContestTransition in={step1In} timeout={1000} enter={false}>
        {(state) => (
          <div
            style={{
              opacity: 1,
              ...step1TransitionStyles[state],
            }}
            className={`step1Wrapper ${state}`}
          >
            <Step1 onSubmit={handleStep1Submit} stepExecution={step1In} />
          </div>
        )}
      </ContestTransition>

      <ContestTransition in={step2In} timeout={500}>
        {(state) => (
          <div
            style={{
              ...step2TransitionStyles[state],
            }}
            className={`step2Wrapper ${state}`}
          >
            <Step2 onSubmit={handleStep2Submit} stepExecution={step2In} />
          </div>
        )}
      </ContestTransition>

      <Transition in={step3In} timeout={500}>
        {(state) => (
          <div
            style={{
              ...step3TransitionStyles[state],
            }}
            className={`step3Wrapper ${state}`}
          >
            <Step3 onSubmit={handleStep3Submit} stepExecution={step3In} />
          </div>
        )}
      </Transition>

      <Transition in={step4In} timeout={500}>
        {(state) => (
          <div
            style={{
              ...step4TransitionStyles[state],
            }}
            className={`step4Wrapper ${state}`}
          >
            <Step4 onSubmit={handleStep4Submit} stepExecution={step4In} />
          </div>
        )}
      </Transition>

      <Transition in={step5In} timeout={500}>
        {(state) => (
          <div
            style={{
              ...step5TransitionStyles[state],
            }}
            className={`step5Wrapper ${state}`}
          >
            <Step5 stepExecution={step5In} />
          </div>
        )}
      </Transition>
    </Wrapper>
  )
}
